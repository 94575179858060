import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/layout/layout";

const useStyles = makeStyles((theme) => ({
  row1: {
    textAlign: "left",
    maxWidth: "50%",
    margin: `${theme.spacing(6)}px 0`,
  },
  mT: {
    marginTop: theme.spacing(2),
  },
  mB: {
    marginBottom: theme.spacing(2),
  },
  em: {
    color: theme.palette.primary.light,
  },
}));

const Legal = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();

  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs={8} className={classes.row1}>
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <Typography variant={`h${num}`} color="textPrimary" key={num}>
                {`Titre ${num}`}
              </Typography>
            ))}
            <Typography variant="body1" color="textPrimary">
              body1
            </Typography>
            <Typography variant="body2" color="textPrimary">
              body2
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Legal;

export const query = graphql`
  query ShowCaseQueryEn {
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
